<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card :color="dark" class="" @drop.prevent="drop">
      <input type="file" id="browse" ref="files" multiple name="browse" @change="handleFilesUpload()" style="display: none" >
      <v-card-title class=""  >
        <span v-if="files.length !== 0">Upload files</span>
        <v-spacer></v-spacer>
        <v-btn color="blue" large v-if="files.length !== 0" @click="upload()">Add Files</v-btn>
        <v-divider class="mx-5" vertical v-if="files.length !== 0"></v-divider>
        <v-btn
          icon
          tile
          :color="modalColor"
          @click="modalOff"
          class="mr-15"
        >
          <v-icon large>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-spacer></v-spacer>

      <v-card-text v-if="files.length === 0">
        <v-row class="mt-10">
          <v-col cols="3"></v-col>
          <v-col cols="6">
            <div class="text-center">
              <v-icon large color="blue">
                mdi-arrange-bring-forward
              </v-icon>

              <h3 class="mt-5">Drag & Drop your files here</h3>

              <v-row no-gutters class="mx-auto">
                <v-col class="ml-10 mr-5">
                  <v-divider class="mt-10  mb-5"></v-divider>
                </v-col>
                <v-col cols="1" class="mt-7 ">
                  OR
                </v-col>
                <v-col class="mr-10 ml-5">
                  <v-divider class="mt-10  mb-7"></v-divider>
                </v-col>
              </v-row>

              <v-btn
                large
                width="200px"
                rounded
                color="blue"
                @click="upload()"
              >
                Browse</v-btn
              >
            </div>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else>
        <v-alert
          :type="actionClass"
          class="mr-15"
          v-if="message"
          dismissible
        >
          {{ message }}
        </v-alert>
        <div class="" v-for="(file, key) in files" :key="key">
          <FileUploadComponent :file="file" @delete="handleDelete" />
          <v-divider class="mt-n5 ml-5 mr-15 pa-0"></v-divider>
        </div>
        <!-- <div class="text-center mt-5">
          <v-btn width="150px" color="primary" large rounded @click="submitFiles" :loading="loading"> Upload Files</v-btn>
        </div> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin"

export default {
  name: "UploadModal",
  components: {
    FileUploadComponent: () => import ('@/modules/Profile/components/fileUploadComponent'),
  },
  props: ["show"],
  mixins: [colorMxn, validationMxn],
  data() {
    return {
      dialogue: false,
      after: true,
      uploaded: true,
      files: [],
      loading: false,
      actionClass: 'success',
      message: '',
    };
  },
  computed: {
    ...mapGetters(['getUploadFile', 'getSession']),
  },
  watch: {
    getUploadFile(val) {
      this.files = val
    }
  },
  mounted() {
    this.files = this.getUploadFile;
  },
  methods: {
    ...mapActions(['performPutActions', 'refreshAuthToken']),
    removeFile(key) {
      this.setRemoveFile(key);
    },
    modalOff() {
      this.$emit('close')
    },
    upload(){
      document.getElementById('browse').click();
    },
    handleFilesUpload() {
      const uploadedFiles = this.$refs.files.files;
      this.files = uploadedFiles
    },
    async submitFiles() {
      this.loading = true;
      const formData = new FormData()

      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];
        formData.append('profile_pic', file);
      }

      const fullPayload = {
        params: formData,
        endpoint: '/profile/upload/avatar'
      }

      const response = await this.uploadImage(fullPayload);
      if (response.status !== "moved") {
        this.actionClass = "error";
        this.message = 'An error Occurred';
        this.loading = false;
        return;
      }
      await this.update(response.fileName);
    },
    async update(fileName) {
			this.error = '';
			const session = this.getSession;
			const payload = {
				avatar: fileName,
			}
      const endpoint = session.user_type === 1 ? `/profile/update-customer/${session.customer.id}` : `/profile/update-writer/${session.writer.id}`
			const fullPayload = {
				params: payload,
				endpoint: endpoint,
			}
			
			const response = await this.performPutActions(fullPayload);
			this.message = response.message;
			this.actionClass = response.status ? "success" : "error";
      this.loading = false;
			if (response.status) {
        // this.$emit('uploadedFile', fileName);
				await this.refreshAuthToken();
			}
		},
    handleDelete() {
      this.files = '';
    }
  }
};
</script>
